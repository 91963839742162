<template>
  <b-img
    height="35px"
    :src="appLogoImage"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      BImg,
      appLogoImage,
    }
  },
}
</script>
